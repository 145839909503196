/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import './layout.css'
import '../fonts/icomoon/fonts/icomoon.eot'
import '../fonts/icomoon/fonts/icomoon.svg'
import '../fonts/icomoon/fonts/icomoon.ttf'
import '../fonts/icomoon/fonts/icomoon.woff'

import '../fonts/icomoon/style.css';

const StyledLayout = styled.div`

  @media screen and (max-width: 780px) {
    display: block;
    padding: 0;
  }
  ////display: flex;
  display: grid;
 //// flex-wrap: wrap;
  //grid-template-columns: 150px auto;
  grid-template-columns: 30% 70%;//1fr 2fr;
  column-gap: 2em;
 row-gap: 1em;
  padding: 0 2em;

  ${({ layout }) => layout === 'column' && `
    flex-direction: column;
  `}

  & .header {
    
    height: 100%;
   ///width: 30%;
    @media screen and (max-width: 780px) {
      height: auto;
      width: 100%;
    }
  }

  & .page {
    //width: 70%;
    @media screen and (max-width: 780px) {
    //  width: 100%;
    }
  }

  & .container {
    //width: 70%
    //max-width: 60em;
    //min-width: 25em;

   
  }
`

const Layout = ({ props, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return <StyledLayout siteMetadata={data.site.siteMetadata} {...props}>{children}</StyledLayout>
}

export default Layout